import React, { memo } from 'react';
import { navigate } from 'gatsby';
import constant from '../../utils/constant';

const Admin = memo(() => {
   navigate(constant.ROUTE_ADMIN_PRODUCTS, { replace: true });
   return null;
});

export default Admin;
